import React from 'react'
import { PageProps } from 'gatsby'
import Layout from '@/shared/globalStyle'
import IndexPageLayout from '@/components/templates/IndexPageLayout'
import SEO from '@/shared/seo'

const Home: React.FC<PageProps> = () => (
  <Layout path={'/'}>
    <SEO title="SHIBUYA with PARK & STREET" lang="ja" />
    <IndexPageLayout />
  </Layout>
)

export default Home
