import React from 'react'
import { styled } from 'twin.macro'
const ProgressBar = styled.div<{
  progress: number
  color: string
  num: number
}>`
  width: 234px;
  height: 3px;
  background-color: #d9d9d9;
  position: relative;
  &::after {
    content: '';
    width: ${({ progress, num }) => (progress / num) * 100}%;
    height: 100%;
    position: absolute;
    background-color: ${({ color }) => color};
  }
`
export default ProgressBar
