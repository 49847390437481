import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import 'twin.macro'
import MainVisual from '../organisms/Mainvisual'
import Whats from '../organisms/Whats'
import Miyashita from '../organisms/Miyashita'
import Kitaya from '../organisms/Kitaya'
// import News from '../organisms/News'
import Partners from '../organisms/Partners'
import Contact from '../organisms/Contact'

const IndexPageLayout = () => {
  const parks = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "parks" } } }
        sort: { fields: frontmatter___id, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              name_en
              name_short
              color
              description
              instagram
              id
              logo {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              image1 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              image2 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              image3 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              image4 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              image5 {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `).allMarkdownRemark.edges.map((edge) => edge.node.frontmatter)
  console.log(3 % 2)
  return (
    <>
      <MainVisual />
      <Whats />
      {parks.map((park, i) =>
        i % 2 === 0 ? (
          <Miyashita park={park} key={i} />
        ) : (
          <Kitaya park={park} key={i} />
        ),
      )}
      {/* <News /> */}
      <Contact />
      <Partners />
    </>
  )
}

export default IndexPageLayout
