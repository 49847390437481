import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import 'twin.macro'

const Weather: React.FC = () => {
  const queryParams = new URLSearchParams({
    appid: process.env.GATSBY_WEATHER_ID,
    q: 'TOKYO',
    units: 'metric',
    lang: 'ja',
  })
  const [weather, setWeather] = useState(null)
  const [temp, setTemp] = useState('20')
  const endpoint = `https://api.openweathermap.org/data/2.5/weather?${queryParams.toString()}`
  async function fetchWeather() {
    const response = await fetch(endpoint)
    const data = await response.json()
    setWeather(data.weather[0])
    setTemp(data.main.temp)
  }
  const images = useStaticQuery(graphql`
    query IconQuery {
      allFile(filter: { relativeDirectory: { eq: "icons" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `).allFile.edges.map((edge) => edge.node)
  function getIcon() {
    const iconData = images.find(
      ({ name }) => name === weather.icon.slice(0, 2) + 'd',
    )
    return iconData?.childImageSharp?.gatsbyImageData
  }
  useEffect(() => {
    fetchWeather()
  }, [])
  return (
    <div tw="bg-smoke_glass text-white rounded-3xl lg:py-6 py-4 lg:px-10 px-7 w-max">
      <p tw="font-en lg:text-lg text-xs">TODAY’s SHIBUYA CITY</p>
      <div tw="flex justify-between items-center lg:mt-0 mt-2">
        {weather && (
          <GatsbyImage
            tw="lg:w-14 w-10"
            image={getIcon()}
            alt={weather.description}
          />
        )}
        <div tw="bg-white w-0.5 h-9"></div>
        {weather && (
          <p tw="font-en lg:text-5xl text-3xl ">
            {parseInt(temp)}
            <small tw="lg:text-3xl text-xl ml-2">℃</small>
          </p>
        )}
      </div>
    </div>
  )
}

export default Weather
