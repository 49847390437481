import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import 'twin.macro'

const Partners: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "partners" } } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              description
              logo {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `).allMarkdownRemark.edges.map((edge) => edge.node.frontmatter)
  return (
    <section tw="bg-white lg:pt-36 pt-20 lg:pb-80 pb-44">
      <h2 tw="font-en text-center lg:text-6xl text-4xl">PARTNERS</h2>
      <div tw="max-width[840px] mx-auto lg:pt-20 px-5 pt-9">
        <div tw="grid lg:grid-cols-4 grid-cols-2 lg:gap-16 gap-10 justify-center items-center">
          {data.map((partner) => (
            <a
              href={partner.description}
              target="_blank"
              rel="noopener noreferrer"
              tw="block"
              key={partner.title}
            >
              <GatsbyImage image={getImage(partner.logo)} alt={partner.title} />
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Partners
