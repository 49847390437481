import React, { useState } from 'react'
import tw, { css } from 'twin.macro'
import Wrapper from '../atoms/util/Wrapper'

const hiddenStyle = css`
  height: 0;
  opacity: 0;
  overflow: hidden;
`

const openStyle = css`
  height: auto;
  opacity: 1;
`

const Whats: React.FC = () => {
  const [isOpen, toggle] = useState(false)
  return (
    <section tw="bg-white lg:pt-44 pt-20 text-center">
      <Wrapper>
        <h2 tw="font-en lg:text-6xl text-4xl">What’s about</h2>
        <p tw="font-bold lg:mt-6 mt-3 text-base">
          SHIBUYA with PARK ＆ STREETとは
        </p>
        <div tw="lg:mt-20 mt-10 lg:text-lg lg:leading-loose text-base leading-loose">
          <p>
            SHIBUYA with PARK &
            STEETでは、渋谷の賑わいを創出する公園や道路などの公共空間を
            <br />
            観光資源としての有効活用として捉え、基本情報、イベント、アクセス情報などを掲載し、
            <br />
            渋谷の街を楽しく回遊していただけるよう、情報発信をしていきます。
          </p>
          <div css={isOpen ? openStyle : hiddenStyle} tw="duration-300">
            <p>
              渋谷区では、計画的な行政運営の基本的かつ総合的な指針として、
              <br />
              未来像を「ちがいを ちからに
              変える街。渋谷区」とする新たな基本構想を策定しています。（平成28年10月）
            </p>
            <a
              href="https://www.city.shibuya.tokyo.jp/kusei/shisaku/koso/index.html"
              target="_blank"
              rel="noopener noreferrer"
              tw="underline break-all"
            >
              詳細はこちら
            </a>
            <p>
              東京の最先端を象徴する地域、ファッションをリードする地域、昔ながらの人情味あふれる地域、
              <br />
              閑静な住宅地域など、さまざまな表情をもつ渋谷にとって、自然とふれあう憩いの空間なども重要な都市機能の一つです。
              <br />
              私達は、渋谷の公園や道路などの空間を、みなさんにもっと知って、そして、使っていただきたいと考えています。
            </p>
            <p>
              国内のみならず渋谷を世界に向けて情報発信し、世界中から訪れる方々に
              <br />
              「成熟した国際都市」を体験していただける街・渋谷を目指してまいります。
            </p>
          </div>
        </div>
        <p
          tw="mt-6 text-smoke_dark cursor-pointer"
          onClick={() => {
            toggle(!isOpen)
            console.log(isOpen)
          }}
        >
          + SEE MORE
        </p>
      </Wrapper>
    </section>
  )
}

export default Whats
