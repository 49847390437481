import React, { useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import 'twin.macro'
import Markdown from 'react-markdown'
import ProgressBar from '@/components/molecules/ProgressBar'

const Kitaya = ({ park }) => {
  const [index, setIndex] = useState(0)
  return (
    <section tw="bg-white lg:pt-44 pt-20 relative lg:padding-right[8.854%]">
      <div tw="lg:hidden margin-left[30px]">
        <p
          tw="font-en lg:text-lg text-xs lg:mt-12 lg:pl-10"
          css={{ color: park.color }}
        >
          PARK & STREET
          <span tw="lg:ml-4 lg:text-5xl text-xl">
            {park.id < 10 ? '0' : ''}
            {park.id}
          </span>
        </p>
        <div tw="flex items-center justify-between">
          <div tw="font-en text-3xl" css={{ color: park.color }}>
            <Markdown children={park.name_en} />
          </div>
          <GatsbyImage
            image={getImage(park.logo)}
            alt="miysashita park"
            tw="w-24 mr-5"
          />
        </div>
      </div>
      <div tw="lg:w-2/3 lg:mt-0 mt-3 w-sp_space ml-auto overflow-hidden lg:absolute left-0">
        <Slider
          slidesToShow={1}
          autoplay={true}
          speed={2000}
          autoplaySpeed={3000}
          afterChange={(index) => setIndex(index)}
        >
          <div key={'image1'}>
            <GatsbyImage image={getImage(park.image1)} alt={park.name_en} />
          </div>
          <div key={'image2'}>
            <GatsbyImage image={getImage(park.image2)} alt={park.name_en} />
          </div>
          <div key={'image3'}>
            <GatsbyImage image={getImage(park.image3)} alt={park.name_en} />
          </div>
          {park.image4 && (
            <div key={'image4'}>
              <GatsbyImage image={getImage(park.image4)} alt={park.name_en} />
            </div>
          )}
          {park.image5 && (
            <div key={'image5'}>
              <GatsbyImage image={getImage(park.image5)} alt={park.name_en} />
            </div>
          )}
        </Slider>
      </div>
      <div tw="lg:ml-auto relative z-20 lg:w-1/2">
        <div tw="lg:block hidden">
          <div tw="pl-10 flex justify-end">
            <GatsbyImage
              image={getImage(park.logo)}
              alt="kitaya park"
              tw="width[100%] max-width[252px]"
            />
          </div>
          <p tw="font-en text-lg lg:mt-12 lg:pl-10" css={{ color: park.color }}>
            PARK & STREET
            <span tw="lg:ml-4 lg:text-5xl">
              {park.id < 10 ? '0' : ''}
              {park.id}
            </span>
          </p>
        </div>
        <div tw="lg:p-10 mt-2.5">
          <div
            tw="lg:block hidden font-en text-6xl bg-white text-right lg:ml-32"
            css={{ color: park.color }}
          >
            <Markdown children={park.name_en} />
          </div>
          <div tw="mt-4 lg:block flex justify-center lg:ml-32">
            <ProgressBar
              progress={index}
              color={park.color}
              num={2}
            ></ProgressBar>
          </div>
          <h3 tw="bg-white 2xl:text-2xl text-lg font-bold lg:mt-12 lg:pt-12 pt-5 flex justify-between items-center pl-8 lg:pr-0 pr-8">
            <div tw="w-full">
              <span css={{ color: park.color }}>/</span> {park.title}
            </div>
            {park.instagram && (
              <a
                href={park.instagram}
                target="_blank"
                rel="noopener noreferrer"
                tw="lg:hidden w-10"
              >
                <StaticImage
                  src="../../../static/images/icon_insta.png"
                  alt="instagram"
                />
              </a>
            )}
          </h3>
          <div tw="bg-white pl-8 lg:pr-0 pr-8 2xl:text-xl lg:text-lg text-base lg:leading-normal leading-loose pt-5">
            <Markdown children={park.description} />
          </div>
          <div tw="bg-white flex lg:justify-between justify-end items-end py-10 pl-8 lg:pr-0 pr-8">
            <Link
              to="/parkstreet/"
              tw="font-en lg:text-3xl text-xl pb-1 px-3"
              css={{
                color: park.color,
                borderBottom: `2px solid ${park.color}`,
              }}
            >
              View more
            </Link>
            {park.instagram && (
              <a
                href={park.instagram}
                target="_blank"
                rel="noopener noreferrer"
                tw="lg:block hidden"
              >
                <StaticImage
                  src="../../../static/images/icon_insta.png"
                  alt="instagram"
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Kitaya
