import React from 'react'
import 'twin.macro'
import Wrapper from '../atoms/util/Wrapper'

const Contact: React.FC = () => {
  return (
    <section tw="lg:pt-24 pt-14 bg-white">
      <Wrapper>
        <a
          href="mailto:info@play-shibuya.com"
          tw="bg-smoke_dark block w-full text-center lg:py-7 py-5 text-white font-bold lg:text-2xl text-lg rounded-lg  hover:bg-black duration-300"
        >
          お問い合わせはこちら
        </a>
      </Wrapper>
    </section>
  )
}

export default Contact
